<template>
  <div class="main-container">
    <b-card>
      <div class="container">
        <div>
          <img
            src="@/assets/images/logo/D2ScaleLogo.png"
            alt="success-page"
            style="max-width: 200px;"
          />
        </div>
        <div style="margin-top: 10px;" class="container">
          <h4>Thank you for accepting the invite.</h4>
          <b-button
            variant="primary"
            style="margin-top: 10px;"
            @click="redirectToLogin"
            >Login</b-button
          >
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BButton, BCard } from "bootstrap-vue";
export default {
  name: "InviteSuccessPage",
  components: {
    BButton,
    BCard,
  },
  methods: {
    redirectToLogin() {
      return this.$router.push("/login");
    },
  },
};
</script>

<style>
.container {
  text-align: center;
  justify-content: center;
  align-items: center;
}
.main-container{
  margin: 30px 30px 30px 30px;
  height: 70vh;
}
.container {
  padding: 10px;
  height: auto;
  width: 80%;
  margin: 0 auto;
  align-items: center;
  text-align: center;
}
</style>
